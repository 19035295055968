function initHome() {
  const items = document.querySelectorAll(".posts .card");

  function handleIntersection(entries, observer) {
    console.log(entries);
    entries.map((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("visible");
      } else {
        entry.target.classList.remove("visible");
      }
    });
  }
  let observer = new IntersectionObserver(handleIntersection, {
    threshold: [0.3],
  });

  items.forEach((item) => {
    observer.observe(item);
  });
}
export default initHome;
