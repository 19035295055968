function initPost() {
  const embeds = document.querySelectorAll(".wp-block-embed iframe")
  embeds.forEach(embed => {
    const w = parseInt(embed.getAttribute("width")) * 2;
    const h = parseInt(embed.getAttribute("height")) * 2;
    embed.setAttribute("width", w)
    embed.setAttribute("height", h)
  })
}
export default initPost;
