import { gri } from "./utils";

function initCursor(el) {
  const colours = ["#f54611", "#f3c326", "#09e414", "#0cd9eb", "#d445ff"]

  window.addEventListener("mousemove", (e) => {
    document.body.style.setProperty("--mouse-x", e.clientX + "px");
    document.body.style.setProperty("--mouse-y", e.clientY + "px");
  });

  let oldText = ""
  const elements = document.querySelectorAll("[data-cursor]");
  const cursorEl = document.querySelector(".site-cursor")
  elements.forEach((el) => {
    el.addEventListener("mouseover", () => {
      document.body.classList.add("has-cursor");

      cursorEl.innerText = el.getAttribute("data-cursor")
      if (oldText !== cursorEl.innerText) {
        cursorEl.style.background = colours[gri(0, colours.length - 1)]
      }
      oldText = el.getAttribute("data-cursor")
    });
    el.addEventListener("mouseout", () => {
      document.body.classList.remove("has-cursor");
      cursorEl.innerText = ""
    });
  });
}
export default initCursor;
