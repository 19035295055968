import initCursor from "./initCursor";
import initHome from "./initHome";
import initPost from "./initPost";

document.addEventListener("DOMContentLoaded", () => {
    initCursor();
    initHome();
    initPost();
});

window.addEventListener("scroll", (e) => {
  document.body.style.setProperty("--y", window.scrollY);
});